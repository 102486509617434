
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,500;6..12,600;6..12,800;6..12,900&family=Open+Sans:ital,wght@0,400;0,600;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@300;400;500;600&family=Raleway:ital,wght@0,200;0,300;0,600;1,200;1,400;1,500&family=Shizuru&display=swap');



.App {
  font-family: 'Merriweather', serif;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-family: 'Raleway', sans-serif;
  font-family: 'Shizuru', cursive;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar {
  margin-bottom: 0; /* Remove any bottom margin */
}
.object-fit-cover {
  width: 1921px;
  height: 498px ;
  object-fit: cover; 
  padding-bottom: 20px;
}
.app-container {
  display: flex;
  flex-direction: row;
  height:450;
  
}

/* .carousel-section{
  flex: -1;
} */
/* .about-us-section {
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
} */

.carousel-section {
  background-color: #f0f0f0;
  padding-bottom: 30px;
}

.about-us-section {
  background-color: #ffffff;
}

.App-link {
  color: #61dafb;
}

.about-us-container {
  display: flex;
  align-items: center;
  padding: 30px;
  border: 1px solid #000
}

.image-container {
  flex: 0 0 auto;
  padding-right: 20px;
}

.text-container {
  flex: 1 1 auto;
  padding-left: 20px;
}
.home-h3{
  text-align: center;
  font-weight: bold;
  font-family: mooli, sans-serif;
}
.span-font{
  padding-top: 20px;
  font-family:mooli;
  font-weight: bold;
  font-size: large;
}

.ff-m{
  font-family: 'Merriweather';
}

.rounded-image{
  border-radius: 50%;
}


.rounded-images{
  border-radius: 50%;
  height:300px;
  width:400px
  
}

.rounded-image-about{
  border-radius: 5%;
}
.container-with-padding {
  padding-top: 70px; 
  
}
.h1-about{
  padding:60px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
